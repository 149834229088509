<template>
  <v-dialog :value="value" @input="close" max-width="600px">
    <v-card>
      <v-card-title>
        <div class="headline">{{$t('accounts.users.addDialog.title')}}</div>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row class="ma-2" no-gutters>

            <!-- Email -->
            <v-col cols="12">
              <v-text-field
                :label="$t('accounts.users.labels.email')"
                outlined
                :rules="requiredRules"
                v-model="form.email"
                maxlength="255" />
            </v-col>

            <!-- Firstname -->
            <v-col cols="6">
              <v-text-field
                :label="$t('accounts.users.labels.firstname')"
                outlined
                :rules="requiredRules"
                v-model="form.person.firstname"
                maxlength="255" />
            </v-col>

            <!-- Name -->
            <v-col cols="6">
              <v-text-field
                :label="$t('accounts.users.labels.name')"
                outlined
                :rules="requiredRules"
                v-model="form.person.name"
                maxlength="255" />
            </v-col>

            <!-- Role -->
            <v-col cols="12">
              <v-select
                :items="$t('constants.usersRoles.list')"
                :rules="requiredRules"
                v-model="form.role"
                outlined
                :label="$t('accounts.users.labels.role')"
              />
            </v-col>

            <!-- Lang -->
            <v-col cols="12">
              <SelectSimple
                :items="['fr', 'en']"
                :isRequired="true"
                :value="form.preferences.lang"
                :label="$t('accounts.users.labels.lang')"
                @input="(val) => form.preferences.lang = val" />
            </v-col>

            <!-- Login method -->
            <v-col cols="12">
              <SelectSimple
                :items="['STANDARD', 'SSO']"
                :isRequired="true"
                :value="form.loginMethod"
                :label="$t('accounts.users.labels.loginMethod')"
                @input="(val) => form.loginMethod = val" />
            </v-col>

            <v-col cols="12" class="mt-4">
              <AlertBar />
            </v-col>

            <v-col cols="12">
              <ButtonBarFormDialog
                class="mt-5"
                :isLoadingBtnSave="isLoading.btnSave"
                @cancel="close(false)"
                @submit="customSubmit"/>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogMixin from "@/mixins/dialog"
import formRules from "@/mixins/formRules";

export default {
  name: "AccountUserAddDialog",

  mixins: [dialogMixin, formRules],

  components: {
    SelectSimple: () => import("@/components/Common/Inputs/SelectGeneric"),
    AlertBar: () => import("@/components/Common/Bars/AlertBar"),
    ButtonBarFormDialog: () => import("@/components/Common/Buttons/ButtonBarFormDialog"),
  },

  props: {
    accountType: {type: String, default: null, enum: ["BRAND", "GROUP", "FACTORY"]},
    accountId: {type: String, default: null}
  },

  data() {
    return {

      valid: null,
      isLoadingBtn: {btnSave: false},
      showPassword: false,

      defaultForm: {
        email: "",
        role: "ADMIN",
        person: {
          firstname: "",
          name: ""
        },
        preferences: {
          lang: "fr"
        },
        loginMethod: "STANDARD",
        enabled: false
      }
    }
  },

  methods: {
    customSubmit() {

      if (this.$refs.form.validate()) {
        this.isLoadingBtn.btnSave = true;

        this.$http
            .post(`/users`, {
              ...this.form,
              //account_type: this.accountType,
              account: this.accountId
            },
            {
              headers: {Authorization: "Bearer " + this.$session.get('jwt')}
            })
            .then(res => {
              this.$store.commit("toast/showSuccess", this.$t('accounts.users.addDialog.success'));
              this.close(true);
            })
            .catch(err => {
              if (err && err.response && err.response.status === 400) {
                if (err && err.response.data.type === "EMAIL_EXISTS")
                  this.$store.commit("alert/showError", this.$t('accounts.users.addDialog.errors.EMAIL_EXISTS'));
                else
                  this.$store.commit("alert/showErrorAxios", err);
              } else
                this.$store.commit("alert/showErrorAxios", err);

          })
          .finally(() => {
            this.isLoadingBtn.btnSave = false;
          });
      }
    },
  }
}
</script>

<style scoped>

</style>
